<template>
  <div class="form-group">
    <label :for="id">
      {{ label }} <span v-if="required">*</span>
      <span v-if="tooltip">
        <b-icon :id="id + 'tooltip'" icon="question-circle"></b-icon>
        <b-tooltip :target="id + 'tooltip'" :title="tooltip" placement="right"></b-tooltip>
      </span>
    </label>
    <b-form-datepicker
      :id="id"
      v-model="dateValue"
      :state="valid"
      class="mb-2"
      trim
      :placeholder="placeholder"
      :disabled="disabled"
      :required="required"
      :today-button="true"
      :label-today-button="$t('commonComponent.datepicker.todayLabel')"
      :close-button="true"
      :label-close-button="$t('commonComponent.datepicker.closeLabel')"
      :label-no-date-selected="$t('commonComponent.datepicker.noDateSelectedLabel')"
      :label-prev-month="$t('commonComponent.datepicker.prevMonthLabel')"
      :label-current-month="$t('commonComponent.datepicker.currMonthLabel')"
      :label-next-month="$t('commonComponent.datepicker.nextMonthLabel')"
      :label-prev-year="$t('commonComponent.datepicker.prevYear')"
      :label-next-year="$t('commonComponent.datepicker.nextYear')"
      :label-help="$t('commonComponent.datepicker.labelHelp')"
      :locale="$t('commonComponent.datepicker.localeLang')"
      :max="maxDate"
      @change="cambio($event)"
    ></b-form-datepicker>
    <b-form-invalid-feedback>
      <validations-commons
        :requiredValue="validationsCommons.requiredValue"
        :requiredMessage="validationsCommons.requiredMessage"
        :minValue="validationsCommons.minValue"
        :minMessage="validationsCommons.minMessage"
        :maxValue="validationsCommons.maxValue"
        :maxMessage="validationsCommons.maxMessage"
      ></validations-commons>
    </b-form-invalid-feedback>
    <small v-if="description" class="form-text text-muted">{{ description }}</small>
  </div>
</template>

<script lang="ts" src="./input-date-component.ts"></script>

<style lang="css">
div.b-form-datepicker {
  position: relative;
  top: 8px;
  border: 2px solid #000;
  border-radius: 30px;
  font-family: Montserrat, 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.25rem;
  width: 100%;
}
</style>
