<template>
  <div class="form-group">
    <label :for="id">
      {{ label }} <span v-if="required">*</span>
      <span v-if="tooltip">
        <b-icon :id="id + 'tooltip'" icon="question-circle"></b-icon>
        <b-tooltip :target="id + 'tooltip'" :title="tooltip" placement="right"></b-tooltip>
      </span>
    </label>
    <b-form-select
      :id="id"
      v-model="selectedId"
      :options="options"
      value-field="id"
      text-field="nombre"
      :placeholder="placeholder"
      :state="valid"
      :disabled="readonly"
      @change="cambio($event)"
    >
      <template #first>
        <b-form-select-option :value="undefined" disabled>
          <span v-text="$t('commonComponent.selectOne.firstOption')"></span>
        </b-form-select-option>
      </template>
    </b-form-select>
    <b-form-invalid-feedback>
      <validations-commons
        :requiredValue="validationsCommons.requiredValue"
        :requiredMessage="validationsCommons.requiredMessage"
        :invalidValue="validationsCommons.invalidValue"
        :invalidMessage="validationsCommons.invalidMessage"
      ></validations-commons>
    </b-form-invalid-feedback>
    <small v-if="description" class="form-text text-muted">{{ description }}</small>
  </div>
</template>

<script lang="ts" src="./input-select-one.component.ts"></script>

<style lang="css">
select.custom-select {
  position: relative;
  top: 8px;
  border: 2px solid #000;
  border-radius: 30px;
  font-family: Montserrat, 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 1.25rem;
  width: 100%;
}
</style>
