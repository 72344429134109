<template>
  <div class="form-group">
    <label :for="id">
      {{ label }} <span v-if="required">*</span>
      <span v-if="tooltip">
        <b-icon :id="id + 'tooltip'" icon="question-circle"></b-icon>
        <b-tooltip :target="id + 'tooltip'" :title="tooltip" placement="right"></b-tooltip>
      </span>
      <b-tooltip :target="id" triggers="hover">
        {{ $t('commonComponent.dateOpen.tooltip') }}
      </b-tooltip>
    </label>
    <b-input-group class="mb-3">
      <b-form-input
        :id="id"
        v-model="dateValue"
        type="text"
        trim
        :placeholder="$t('commonComponent.dateOpen.placeholder')"
        autocomplete="off"
        :required="required"
        :today-button="true"
        :label-today-button="$t('commonComponent.datepicker.todayLabel')"
        :close-button="true"
        :label-close-button="$t('commonComponent.datepicker.closeLabel')"
        :label-no-date-selected="$t('commonComponent.datepicker.noDateSelectedLabel')"
        :label-prev-month="$t('commonComponent.datepicker.prevMonthLabel')"
        :label-current-month="$t('commonComponent.datepicker.currMonthLabel')"
        :label-next-month="$t('commonComponent.datepicker.nextMonthLabel')"
        :label-prev-year="$t('commonComponent.datepicker.prevYear')"
        :label-next-year="$t('commonComponent.datepicker.nextYear')"
        :label-help="$t('commonComponent.datepicker.labelHelp')"
        :state="valid"
        :locale="$t('commonComponent.datepicker.localeLang')"
        :max="maxDate"
        :min="minDate"
        @change="cambio($event)"
        @context="onContext"
      ></b-form-input>
      <b-input-group-append>
        <b-form-datepicker
          v-model="dateValue"
          button-only
          right
          aria-controls="example-input"
          @context="onContext"
          :state="valid"
          :locale="$t('commonComponent.datepicker.localeLang')"
          :max="maxDate"
          @change="cambio($event)"
        ></b-form-datepicker>
      </b-input-group-append>
    </b-input-group>
    <b-form-invalid-feedback>
      <validations-commons
        :requiredValue="validationsCommons.requiredValue"
        :requiredMessage="validationsCommons.requiredMessage"
        :minValue="validationsCommons.minValue"
        :minMessage="validationsCommons.minMessage"
        :maxValue="validationsCommons.maxValue"
        :maxMessage="validationsCommons.maxMessage"
      ></validations-commons>
    </b-form-invalid-feedback>
  </div>
</template>

<script lang="ts" src="./input-date-open-component.ts"></script>

<style lang="css">
div.b-form-datepicker {
  border-radius: 0px 10px 10px 0px !important;
  border: 2px solid black !important;
}
button.dropdown-toggle {
  border-radius: 0px 10px 10px 0px !important;
}
.input-group-append {
  display: inline !important;
}
.btn {
  line-height: 1 !important;
}
</style>